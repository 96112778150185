import React from "react";
import { useState } from "react";
import Header2 from "../component/Header2";
import GoDigitalConsultationForm1 from "../assets/GoDigitalConsultationForm1.png";
import "../css/GoDigitalConsultationForm.css";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { log } from "util";

function GoDigitalConsultationForm(props) {
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [namaUsaha, setNamaUsaha] = useState('');
    const [email, setEmail] = useState('');
    const [bidangUsaha, setBidangUsaha] = useState('');
    const [jumlahKaryawan, setJumlahKaryawan] = useState('');
    const [pendapatan, setPendapatan] = useState('');
    const [mediaSosial, setMediaSosial] = useState('');
    const [kegiatanUsaha, setKegiatanUsaha] = useState('');
    const [keunggulanUsaha, setKeunggulanUsaha] = useState('');
    const [kekhawatiran, setKekhawatiran] = useState('');

    function handleSubmit(event) {
        console.log("Nama Usaha: " + namaUsaha);
        // event.preventDefault();

        // axios.post('https://diego.backend.rfstore.net/registerbackend', { namaUsaha: namaUsaha, email: email, bidangUsaha: bidangUsaha, jumlahKaryawan: jumlahKaryawan, pendapatan: pendapatan, mediaSosial: mediaSosial, deksripsiUsaha: deksripsiUsaha, kegiatanUsaha: kegiatanUsaha, keunggulanUsaha: keunggulanUsaha, kekhawatiran: kekhawatiran })
        //         .then(res => {

        //             if (res.status == 200) {
        //                 console.log("Berhasil isi form");
        //                 navigate('/dashboard');
        //             } else {
        //                 // namaLengkap = "tidak ada";
        //             }
        //             // setLoginData("");
        //         })
        //         .catch(err => console.log(err));

        axios.post('https://diego.backend.rfstore.net/formBackend', { namaUsaha: namaUsaha, email: email, bidangUsaha: bidangUsaha, jumlahKaryawan: jumlahKaryawan, pendapatan: pendapatan, mediaSosial: mediaSosial, kegiatanUsaha: kegiatanUsaha, keunggulanUsaha: keunggulanUsaha, kekhawatiran: kekhawatiran })
        .then(res => {

            if (res.status == 200) {
                console.log("Berhasil isi form");
            } else {
                // namaLengkap = "tidak ada";
            }
            // setLoginData("");
        })
        .catch(err => console.log(err));
    }

    return(
        <div className="go-digital-consultation-form">
            <Header2 />

            <div className="section1">
                <h1>{props.judulForm}</h1>
                <p>{props.deskripsiForm}</p>
            </div>

            <div className="section2 flex">
                <div>
                    <h1 className="nomor">
                        #1
                    </h1>
                    <h1 className="judul-nomor">
                        Informasi Umum
                    </h1>

                    <div className="input-data">
                        <label for="namaUsaha" >Nama Usaha</label><br />
                        <input name="namaUsaha" type="text" onChange={e => setNamaUsaha(e.target.value)} />
                    </div>

                    <div className="input-data">
                        <label for="email" >Email (Untuk Invoice)</label><br />
                        <input name="email" type="text" onChange={e => setEmail(e.target.value)} />
                    </div>

                    <div className="input-data">
                        <label for="bidangUsaha" >Bidang Usaha</label><br />
                        <input name="bidangUsaha" type="text" onChange={e => setBidangUsaha(e.target.value)} />
                    </div>

                    <div className="input-data">
                        <label for="jumlahKaryawan" >Jumlah Karyawan yang Dimiliki Saat Ini (Termasuk Pemilik)</label><br />
                        <input name="jumlahKaryawan" type="text" onChange={e => setJumlahKaryawan(e.target.value)} />
                    </div>

                    <div className="input-data">
                        <label for="pendapatanBulan" >Kisaran Pendapatan per Bulan</label><br />
                        <input name="pendapatanBulan" type="text" onChange={e => setPendapatan(e.target.value)} />
                    </div>

                    <div className="input-data">
                        <label for="mediaSosial" >Akun Media Sosial yang Aktif (Jika Ada)</label><br />
                        <input name="mediaSosial" type="text" onChange={e => setMediaSosial(e.target.value)} />
                    </div>
                </div>
                <div className="section2-right">
                    <h1 className="nomor">
                        #2
                    </h1>
                    <h1 className="judul-nomor">
                        Deskripsikan Usahamu
                    </h1>

                    <div className="input-data">
                        <label for="kegiatanUsaha" >Jelaskan secara singkat kegiatan yang usaha Anda lakukan</label><br />
                        <input name="kegiatanUsaha" type="text" onChange={e => setKegiatanUsaha(e.target.value)} />
                    </div>

                    <div className="input-data">
                        <label for="keunggulanUsaha" >Jelaskan secara singkat keunggulan dari usaha Anda</label><br />
                        <input name="keunggulanUsaha" type="text" onChange={e => setKeunggulanUsaha(e.target.value)} />
                    </div>

                    <div className="input-data">
                        <label for="kekhawatiran" >Jelaskan secara singkat kekhawatiran Anda terhadap apa yang terjadi pada bisnis Anda di masa kini atau masa depan</label><br />
                        <input name="kekhawatiran" type="text" onChange={e => setKekhawatiran(e.target.value)} />
                    </div>
                </div>
            </div>

            <div className="section3">
                    <button onClick={() => {
                            handleSubmit();
                            handleShow();
                        }} className="button-selanjutnya">
                        <h1>Submit</h1>
                    </button>
            </div>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                <Modal.Title>Pendaftaran Berhasil</Modal.Title>
                </Modal.Header>
                <Modal.Body>Kamu akan segera dihubungi oleh tim kami</Modal.Body>
                <Modal.Footer>
                <a href="/dashboard">
                    <Button onClick={handleClose}>
                        Close
                    </Button>
                </a>
                </Modal.Footer>
            </Modal>
        </div>

    )
}

export default GoDigitalConsultationForm;