import React from "react";
// import './App.css';
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Dashboard from "./pages/Dashboard";
import Home from "./pages/Home";
import GoDigitalConsultationDetail from "./pages/GoDigitalConsultation";
import JasaGoDigital from "./pages/JasaGoDigital";
import LiveHosting from "./pages/LiveHosting";
import FotoVideoProduk from "./pages/FotoVideoProduk";
import TentangKami from "./pages/TentangKami";
import GoDigitalConsultationForm from "./pages/GoDigitalConsultationForm";
import GoDigitalConsultationForm2 from "./pages/GoDigitalConsultationForm2";

function App() {
  return (
    // Halaman 1    
      <Router>
        <Routes>
          {/* <Route path="/" element={<LandingPage />} /> */}
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/go-digital-consultation" element={<GoDigitalConsultationDetail href={"/register"} />} />
          <Route path="/jasa-go-digital" element={<JasaGoDigital href={"/register"} />} />
          <Route path="/live-hosting" element={<LiveHosting href={"/register"} />} />
          <Route path="/foto-video-produk" element={<FotoVideoProduk href={"/register"} />} />
          <Route path="/go-digital-consultation-log" element={<GoDigitalConsultationDetail href={"/go-digital-consultation-form"} login={"hidden"} />} />
          <Route path="/jasa-go-digital-log" element={<JasaGoDigital login={"hidden"} />} />
          <Route path="/live-hosting-log" element={<LiveHosting href={"/live-hosting-form"} login={"hidden"} />} />
          <Route path="/foto-video-produk-log" element={<FotoVideoProduk href={"foto-video-produk-form"} login={"hidden"} />} />
          <Route path="/tentang-kami" element={<TentangKami />} />
          <Route path="/go-digital-consultation-form" element={<GoDigitalConsultationForm judulForm={"Go Digital Consultation"} deskripsiForm={"Lengkapi datamu untuk memesan layanan Go-Digital Consultation"} />} />
          <Route path="/jasa-go-digital-basic-form" element={<GoDigitalConsultationForm judulForm={"Jasa Go Digital - BASIC"} deskripsiForm={"Lengkapi datamu untuk memesan layanan Jasa Go Digital - BASIC"} />} />
          <Route path="/jasa-go-digital-mid-form" element={<GoDigitalConsultationForm judulForm={"Jasa Go Digital - MID"} deskripsiForm={"Lengkapi datamu untuk memesan layanan Jasa Go Digital - MID"} />} />
          <Route path="/jasa-go-digital-advanced-form" element={<GoDigitalConsultationForm judulForm={"Jasa Go Digital - ADVANCED"} deskripsiForm={"Lengkapi datamu untuk memesan layanan Jasa Go Digital - ADVANCED"} />} />
          <Route path="/live-hosting-form" element={<GoDigitalConsultationForm judulForm={"Live Hosting"} deskripsiForm={"Lengkapi datamu untuk memesan layanan Live Hosting"} />} />
          <Route path="/foto-video-produk-form" element={<GoDigitalConsultationForm judulForm={"Foto dan Video Produk"} deskripsiForm={"Lengkapi datamu untuk memesan layanan Foto dan Video Produk"} />} />
          <Route path="/go-digital-consultation-form2" element={<GoDigitalConsultationForm2 />} />
          <Route path="/" element={<Home />} />
        </Routes>
      </Router>
  );
}

export default App;
