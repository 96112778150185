import React from "react";
import { useState } from "react";
import Header2 from "../component/Header2";
import GoDigitalConsultationForm2Image from "../assets/GoDigitalConsultationForm1.png";
import "../css/GoDigitalConsultationForm2.css";

function GoDigitalConsultationForm2() {
    // const [show, setShow] = useState(false);
    // const handleClose = () => setShow(false);
    // const handleShow = () => setShow(true);

    return(
        <div className="go-digital-consultation-form2">
            <Header2 />

            <div className="section1">
                <img className="section1-img" src={GoDigitalConsultationForm2Image} />
            </div>

            <div className="section2 flex">
                <div>
                    <div className="input-data">
                        <label for="namaLengkap" >Nama Lengkap Pemilik Usaha</label><br />
                        <input className="input1"  name="namaLengkap" type="text" />
                    </div>

                    <div className="input-data">
                        <label for="domisili" >Domisili Pemilik Usaha Saat Ini</label><br />
                        <input className="input2"  name="domisili" type="text" />
                    </div>

                    <div className="input-data2">
                        <label for="pencaharianUtama" >Apakah bisnis ini adalah sumber pencaharian utama? Jika tidak, tuliskan pekerjaan atau usaha lain yang dimiliki!</label><br />
                        <div>
                            <input type="radio" name="pilihanPencaharian" id="" />
                            <label className="labelRadio" For="">Ya</label><br />
                            <input type="radio" name="pilihanPencaharian" id="" />
                            <label className="labelRadio" For="">Tidak</label>
                            <input className="textRadio" type="text" />
                        </div>
                    </div>
                </div>

                <div className="input-data">
                    <label for="tanggungJawab" >Jelaskan secara singkat tanggung jawab pemilik usaha</label><br />
                    <input className="input4" name="tanggungJawab" type="text" />
                </div>
            </div>

            <div className="section3 flex">
                    <button className="kembaliButton">
                        Kembali
                    </button>
                    <button className="selesaiButton">
                        Selesai
                    </button>
            </div>

            
        </div>
    );

}

export default GoDigitalConsultationForm2;
